<template>
  <pz-form-flujo
    v-model="formulario_garante['captcha']"
    :available-captcha="extraData.withCaptcha"
    :forward="handleSave"
    :backwards="showBack?handleBack:null"
  >
    <span slot="form_title">
      {{ $t('forms_flujo.form_garante.MX.title') }}
    </span>
    <el-form
      slot="formulario"
      :ref="reference"
      label-position="top"
      :model="formulario_garante"
      status-icon
      :rules="rules_formulario_garante"
    >
      <el-col
        :xl="{span: 12}"
        :lg="{span: 12}"
        :md="{span: 12}"
        :sm="{span: 24}"
        :xs="{span: 24}"
        class="margin"
      >
        <el-form-item
          prop="numero_documento"
          :label="$t('forms_flujo.form_garante.UY.identity_doc')"
          :error="errors.get('numero_documento')"
        >
          <el-input
            v-model="formulario_garante.numero_documento"
            :type="'text'"
            auto-complete="no"
            :placeholder="$t('forms_flujo.form_garante.MX.identity_doc')"
          />
        </el-form-item>
        <el-form-item
          prop="nivel3"
          :label="$t('forms_flujo.form_garante.MX.level_3')"
          :error="errors.get('nivel3')"
        >
          <el-select
            v-model="formulario_garante.nivel3"
            clearable
            :placeholder="$t('forms_flujo.form_garante.MX.level_3')"
            @change="getLocalidad()"
          >
            <el-option
              v-for="item in municipios"
              :key="item.id"
              :label="item.nombre"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          prop="nivel5"
          :label="$t('forms_flujo.form_garante.MX.address')"
          :error="errors.get('nivel5')"
        >
          <el-input
            v-model="formulario_garante.nivel5"
            :type="'text'"
            auto-complete="no"
            :placeholder="$t('forms_flujo.form_garante.MX.address')"
          />
        </el-form-item>
        <el-form-item
          prop="fecha_ingreso"
          :label="$t('forms_flujo.form_garante.MX.fecha_ingreso')"
          :error="errors.get('fecha_ingreso')"
        >
          <el-date-picker
            v-model="formulario_garante.fecha_ingreso"
            type="date"
            :picker-options="pickerOptions1"
            format="dd/MM/yyyy"
            value-format="dd/MM/yyyy"
            :placeholder="$t('forms_flujo.form_garante.MX.fecha_ingreso')"
          />
        </el-form-item>
        <el-form-item
          prop="ingreso_mensual"
          :label="$t('forms_flujo.form_garante.MX.ingreso_mensual')"
          :error="errors.get('ingreso_mensual')"
        >
          <el-input
            v-model.number="formulario_garante.ingreso_mensual"
            :type="'text'"
            auto-complete="no"
            :placeholder="$t('forms_flujo.form_garante.MX.ingreso_mensual')"
          />
        </el-form-item>
      </el-col>
      <el-col
        :xl="{span: 12}"
        :lg="{span: 12}"
        :md="{span: 12}"
        :sm="{span: 24}"
        :xs="{span: 24}"
        class="margin"
      >
        <el-form-item
          prop="nivel2"
          :label="$t('forms_flujo.form_garante.MX.state')"
          :error="errors.get('nivel2')"
        >
          <el-select
            v-model="formulario_garante.nivel2"
            clearable
            :placeholder="$t('forms_flujo.form_garante.MX.state')"
            @change="getMunicipio()"
          >
            <el-option
              v-for="item in provincias"
              :key="item.id"
              :label="item.departamento"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          prop="nivel4"
          :label="$t('forms_flujo.form_garante.MX.level_4')"
          :error="errors.get('nivel4')"
        >
          <el-select
            v-model="formulario_garante.nivel4"
            clearable
            :placeholder="$t('forms_flujo.form_garante.MX.level_4')"
          >
            <el-option
              v-for="item in localidad"
              :key="item.id"
              :label="item.nombre"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          prop="telefono"
          :label="$t('forms_flujo.form_garante.MX.phone')"
          :error="errors.get('telefono')"
        >
          <el-input
            v-model="formulario_garante.telefono"
            :type="'text'"
            auto-complete="no"
            :placeholder="$t('forms_flujo.form_garante.MX.phone')"
          />
        </el-form-item>
        <el-form-item
          prop="telefono_empresa"
          :label="$t('forms_flujo.form_garante.MX.company_phone')"
          :error="errors.get('telefono_empresa')"
        >
          <el-input
            v-model="formulario_garante.telefono_empresa"
            :type="'text'"
            auto-complete="no"
            :placeholder="$t('forms_flujo.form_garante.MX.company_phone')"
          />
        </el-form-item>
      </el-col>
    </el-form>
  </pz-form-flujo>
</template>

<script>
    import {required, type} from "@/commons/utils/Rules";
    import { getDepartamentos, getMunicipios,getLocalidad} from "@/routes/api/resources";
    import baseGarante from '../baseGarante'
    import {mapGetters} from "vuex";

    export default {
        name: 'PzGaranteFormMx',
        components: {},
        mixins: [baseGarante],
        data() {
            var checCIGarante = (rule, value, callback) => {
                if (this.form_product.cedula === this.formulario_garante.numero_documento) {
                    return callback(new Error(this.$t('forms_flujo.form_garante.MX.error_applicant')));
                } else {
                    return callback();
                }
            };
            return {
                employment_relations: [],
                departamentos: [],
                municipios: [],
                localidad: [],
                provincias: [],
                formulario_garante: {
                    numero_documento: '',
                    telefono: '',
                    fecha_nacimiento: '',
                    relacion_laboral: '',
                    telefono_empresa: '',
                    fecha_ingreso: '',
                    ingreso_mensual: '',
                    url_trackeo: '',
                    ref_empresa: process.env.COMPANY_KEY,
                    tipo_documento: process.env.IDE_DEFAULT,
                    nivel2:'',
                    nivel3:'',
                    nivel4:'',
                    nivel5:'',
                },
                disable: true,
                rules_formulario_garante: {
                    numero_documento: [
                        required(''),
                        {validator: checCIGarante, trigger: 'blur'}
                    ],
                    telefono:
                            [
                                required('')
                            ],
                    nivel2: [
                        required('')
                    ],
                    nivel3: [
                        required('')
                    ],
                    nivel4: [
                        required('')
                    ],
                    nivel5: [
                        required('')
                    ],
                    relacion_laboral:
                            [
                                required('')

                            ],
                    telefono_empresa:
                            [
                                required('')

                            ],
                    fecha_ingreso:
                            [
                                required('')

                            ],
                    ingreso_mensual:
                            [
                                required(''),
                                type({field: '', type: 'number'}),
                            ],

                },
                pickerOptions1: {
                    disabledDate(time) {
                        return time.getTime() > Date.now();
                    },
                },

            }
        },
        computed: {
            ...mapGetters(['product', 'form_product', 'current_step']),
        },
        provide: function () {
            return {
                myForm: this
            }
        },
        created: function () {
            this.fetchData();
        },
        methods: {
            fetchData() {
                this.$store.dispatch('loader/up',{trigger:this.$options.name});
                this.inlocal = 'formGarante';
                this.reference = 'formulario_garante';
                let deptos = getDepartamentos().then((res) => {
                    this.provincias = res.data;
                }).catch(() => {
                    this.provincias = [];
                });
                let municipios = getMunicipios(this.formulario_garante.nivel2).then((res) => {
                    this.municipios = res.data;
                }).catch(() => {
                    this.municipios = [];
                });

                let localidades = getLocalidad(this.formulario_garante.nivel3).then((res) => {
                    this.localidad = res.data;
                }).catch(() => {
                    this.localidad = [];
                });
                return Promise.all([deptos, municipios, localidades]).
                finally(() => {this.$store.dispatch('loader/down',{trigger:this.$options.name})});
            }
        },
    }
</script>
